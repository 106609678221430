<template>
 <div class="RelatorioExportacoes">
   <relatorio-exportacao-investimento-cliente
     v-if="acessoRelatorioInvestimentoCliente"
     @RelatorioExportacaoInvestimentoCliente__AplicaFiltros="aplicaFiltros"
   />

   <relatorio-exportacao-investimento-regional
     v-if="acessoRelatorioInvestimentoRegional"
     @RelatorioExportacaoInvestimentoRegional__AplicaFiltros="aplicaFiltros"
   />
   <relatorio-exportacao-saldo-provisao
      v-if="acessoRelatorioSaldoProvisao"
      @RelatorioExportacaoSaldoProvisaoBloqueadas__AplicaFiltros="aplicaFiltros"
    />
    <relatorio-exportacao-contrato
      v-if="acessoRelatorioContrato"
      @RelatorioExportacaoContrato__AplicaFiltros="aplicaFiltros"
    />
    <relatorio-exportacao-inconsistencias-integracao
      v-if="true"
      @RelatorioExportacaoInconsistenciasIntegracao__AplicaFiltros="aplicaFiltros"
    />
 </div>
</template>
<script>
import { mapGetters } from 'vuex';
import RelatorioExportacaoInvestimentoRegional
  from './investimento-regional/RelatorioExportacaoInvestimentoRegional';
import RelatorioExportacaoInvestimentoCliente
  from './investimento-cliente/RelatorioExportacaoInvestimentoCliente';
import RelatorioExportacaoSaldoProvisao from './RelatorioExportacaoSaldoProvisao';
import RelatorioExportacaoContrato from './RelatorioExportacaoContrato';
import RelatorioExportacaoInconsistenciasIntegracao from './inconsistencias-integracao/RelatorioExportacaoInconsistenciasIntegracao';
import { removeEmptyProperties } from '../../../produto/common/functions/helpers';
import exportacao from '../../../produto/common/functions/exportacao';

export default {
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    acessoRelatorioInvestimentoCliente() {
      return this.getAllRoles
        .filter((el) => el.indexOf('REL_INVEST_CLIENTE') !== -1).length > 0;
    },
    acessoRelatorioInvestimentoRegional() {
      return this.getAllRoles
        .filter((el) => el.indexOf('REL_INVEST_REGIONAL') !== -1).length > 0;
    },
    acessoRelatorioSaldoProvisao() {
      return this.getAllRoles
        .filter((el) => el.indexOf('REL_SALDO_PROVISAO') !== -1).length > 0;
    },
    acessoRelatorioContrato() {
      return this.getAllRoles
        .filter((el) => el.indexOf('REL_CONTRATO') !== -1).length > 0;
    },
  },
  components: {
    RelatorioExportacaoInvestimentoRegional,
    RelatorioExportacaoInvestimentoCliente,
    RelatorioExportacaoSaldoProvisao,
    RelatorioExportacaoContrato,
    RelatorioExportacaoInconsistenciasIntegracao,
  },
  data() {
    return {
      filtros: null,
      bloquearExportacao: false,
    };
  },
  methods: {
    aplicaFiltros(filtrosAplicados, tipoRelatorio, modulo) {
      console.table('teste');
      if (!filtrosAplicados) {
        return;
      }
      this.filtros = {
        ...filtrosAplicados,
      };
      this.requestExportacao(this.filtros, tipoRelatorio, modulo);
    },
    requestExportacao(filtros, tipoRelatorio, modulo = 'liquidacao_acao') {
      const filtroTratado = removeEmptyProperties(filtros);
      this.bloquearExportacao = true;

      exportacao.exportar(() => {
        this.bloquearExportacao = false;
      }, tipoRelatorio, this, filtroTratado, 5000, 0, modulo);
    },
  },
};
</script>
<style>
.RelatorioExportacoes {
  padding-bottom: 60px;
}
</style>
