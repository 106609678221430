const Liquidacao = () => import('../../../spa/liquidacao-acao/Liquidacao');
const LiquidacaoListNivel1 = () => import('../../../spa/liquidacao-acao/LiquidacaoListNivel1');
const LiquidacaoListNivel2 = () => import('../../../spa/liquidacao-acao/LiquidacaoListNivel2');
const LiquidacaoListNivel3 = () => import('../../../spa/liquidacao-acao/LiquidacaoListNivel3');
const PagamentoDetalhes = () => import('../../../spa/liquidacao-acao/pagamento/PagamentoDetalhes');
const LiquidacaoEmLista = () => import('@/spa/liquidacao-acao/LiquidacaoEmLista');

export default {
  path: 'pagamento',
  component: Liquidacao,
  children: [
    {
      path: '',
      name: 'pagamento',
      component: LiquidacaoListNivel1,
    },
    {
      path: 'lista',
      name: 'liquidacaoEmLista',
      component: LiquidacaoEmLista,
    },
    {
      path: 'cliente/:idCliente',
      name: 'nivel2PagamentoCliente',
      component: LiquidacaoListNivel2,
    },
    {
      path: 'fornecedor/:idFornecedor',
      name: 'nivel2PagamentoFornecedor',
      component: LiquidacaoListNivel2,
    },
    {
      path: 'cliente/:idCliente/acao_massiva/:idAcaoPrevia',
      name: 'nivel2PagamentoClienteMassivo',
      component: LiquidacaoListNivel3,
    },
    {
      path: 'fornecedor/:idFornecedor/acao_massiva/:idAcaoPrevia',
      name: 'nivel2PagamentoFornecedorMassivo',
      component: LiquidacaoListNivel3,
    },
    {
      path: 'status/:status',
      name: 'listarPagamentosNivel2Status',
      component: LiquidacaoListNivel2,
    },
    {
      path: 'status/:status',
      name: 'listarPagamentosNivel3Status',
      component: LiquidacaoListNivel3,
    },
    {
      path: 'status/:status/acao_massiva/:idAcaoPrevia',
      name: 'listarPagamentosNivel3StatusMassivo',
      component: LiquidacaoListNivel3,
    },
    {
      path: 'status/:status/acao/:idAcao/pagamento/:id',
      name: 'detalharPagamentoStatus',
      component: PagamentoDetalhes,
    },
    {
      path: 'cliente/:idCliente/acao/:idAcao/pagamento/:id',
      name: 'detalharPagamentoCliente',
      component: PagamentoDetalhes,
    },
    {
      path: 'fornecedor/:idFornecedor/acao/:idAcao/pagamento/:id',
      name: 'detalharPagamentoFornecedor',
      component: PagamentoDetalhes,
    },
    {
      path: 'status/:status/acao_massiva/:idAcaoPrevia/acao/:idAcao/pagamento/:id',
      name: 'detalharPagamentoStatusMassivo',
      component: PagamentoDetalhes,
    },
    {
      path: 'cliente/:idCliente/acao_massiva/:idAcaoPrevia/acao/:idAcao/pagamento/:id',
      name: 'detalharPagamentoClienteMassivo',
      component: PagamentoDetalhes,
    },
    {
      path: 'fornecedor/:idFornecedor/acao_massiva/:idAcaoPrevia/acao/:idAcao/pagamento/:id',
      name: 'detalharPagamentoFornecedorMassivo',
      component: PagamentoDetalhes,
    },
    {
      path: ':id/detalhamento/origem/:origem',
      name: 'detalharPagamentoOrigem',
      component: PagamentoDetalhes,
    },
  ],
};
