import getBasePath from '../../functions/api-resource';
import resourceBuilder from '../../functions/metadados-resource-builder';

const basePath = getBasePath('apuracao_acao', 'listagem');

const apuracaoActions = {
  extensoes: { method: 'GET', url: `${basePath}/extensoes-foco-acao` },
  buscarApuracao: { methods: 'GET', url: `${basePath}/carregamento/{idApuracao}` },
  buscarVisoes: { methods: 'GET', url: `${basePath}/visoes` },
  verificarDadosExportacao: { methods: 'GET', url: `${basePath}/{idApuracao}/verificar-dados-exportacao` },
  verificarSeUsuarioPossuiAcesso: { methods: 'GET', url: `${basePath}/acesso/{idApuracao}` },
  usuarioConcluiApuracaoSemFluxo: { method: 'GET', url: `${basePath}/usuario-conclui-sem-fluxo/{idApuracao}` },
};

export default (resource) => resource(`${basePath}`, {}, apuracaoActions);

export const listarApuracoesTotalizadores = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'totalizadores', parametros).doGet();

export const listarApuracoesPorStatus = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'por-status', parametros).doGet();

export const listarApuracoesPorCliente = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'por-cliente', parametros).doGet();

export const listarApuracoesPorHolding = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'por-holding', parametros).doGet();

export const listarApuracoesPorNivelExtensao = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'por-nivel-extensao', parametros).doGet();

export const listarApuracoesPorFornecedor = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'por-fornecedor', parametros).doGet();

export const listarApuracoesPorFornecedorPagador = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'por-fornecedor-pagador', parametros).doGet();

export const listarApuracoesPorGrupoFornecedor = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'por-grupo-fornecedor', parametros).doGet();

export const listarApuracoesPorRegional = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'por-regional', parametros).doGet();

export const listarApuracoesNivel2 = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'nivel2', parametros).doGet();

export const listarApuracoesNivel2Simplificado = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'nivel2/simplificado', parametros).doGet();

export const listarApuracoesNivel3 = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'nivel3', parametros).doGet();

export const listarApuracoesNivel3Simplificado = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'nivel3/simplificado', parametros).doGet();
